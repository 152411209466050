import Script from "next/script";
export default function Snow_fx() {
  return (
   <>
   {/* Snow */}
  {/* <Script async strategy="lazyOnload"  
       dangerouslySetInnerHTML={{
            __html: `
            jQuery(document).ready(function($){
      $(document).snowFlurry({
      maxSize: 5,
      numberOfFlakes: 50,
      minSpeed: 10,
      maxSpeed: 15,
      color: '#fff',
      timeout: 0
      });
      });
          `,
          }} /> */}

   
   </>
  );
}
